var hmct = function () {
	var close = document.querySelector('.js-floating-close'),
	    expand = document.querySelector('.hmct-floating-el-collapsed'),
		helpBox = document.querySelector('.hmct-floating-el'),
		helpBoxCollapsed = document.querySelector('.hmct-floating-el-collapsed'),
		hero = $('.banner.banner-top'),
		timer = null;

	close.addEventListener('click', function() {
		classToggler();
	});

	expand.addEventListener('click', function() {
		classToggler();
	});

	function classToggler() {
		helpBox.classList.toggle("collapse");
		helpBoxCollapsed.classList.toggle("expand");
	}

	if (hero.length > 0) {
		window.addEventListener('scroll', throttle(300));

		function throttle(wait) {
			var time = Date.now();
			return function() {
				if ((time + wait - Date.now()) < 0) {
					position();
					if(timer !== null) {
						clearTimeout(timer);        
					}
					timer = setTimeout(function() {
						position();
					}, 310);
					time = Date.now();
				}
			}
		}

		function position() {
			if(hero.position().top + hero.outerHeight(true) < ((window.scrollY + window.innerHeight) - 150)) {
				helpBox.classList.remove("hero-overflow");
				helpBoxCollapsed.classList.remove("hero-overflow");
			} else {
				helpBox.classList.add("hero-overflow");
				helpBoxCollapsed.classList.add("hero-overflow");
			}
		}
	}

	return {
		interval : function(interval) {
			var downloadButton = document.querySelectorAll('[href*="download-thank-you.php"]'),
				buttonClicked = false;

			if (downloadButton.length > 0) {
				downloadButton.forEach(function (button) {
					button.addEventListener('click', function() {
						buttonClicked = true;
					})
				});
			}
			if(sessionStorage.getItem('hmct')) {
				helpBox.classList.add("hmct-show");
			} else {
				$(window).on('load', function() {
					setTimeout(function() {
						if (hero.length > 0) {
							position();
						}
						if (!buttonClicked) {
							helpBox.classList.add("hmct-show");
						}
					}, interval);
				});
			}
		}
	}
	
}();
